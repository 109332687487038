import {DocumentBase} from '.';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type ScheduleAFeeRiskModel = {
  accountOnFile: number;
  annualFee: number;
  annualPCI: number;
  annualPCIWithBreachInsurance: number;
  avsElectronic: number;
  avsVoice: number;
  batch: number;
  chargeBack: number;
  debitAccess: number;
  gatewayMonthly: number;
  gatewaySetup: number;
  gatewayTransaction: number;
  irsRegulatoryFee: number;
  merchantClub: number;
  merchantOnlineAccess: number;
  monthlyMinimum: number;
  monthlyMonitoring: number;
  monthlyProcessingVolumePercent: number;
  otherGateway: number;
  otherTransaction: number;
  pinDebitEBT: number;
  retrieval: number;
  pinDebitSponsorPercent: number;
  visaMCDiscAmexBINSponsorPercent: number;
  visaMCDiscAmexBINTransaction: number;
  wirelessTerminalMonthly: number;
  wirelessTransaction: number;
};

export type ScheduleA = DocumentBase & ScheduleABase;
export enum SplitMethodType {
  direct = 'direct',
  splitOfSplit = 'splitOfSplit',
}

export type ScheduleABase = {
  version: number;
  splitMethod: SplitMethodType | undefined;
  riskModels: {
    low: ScheduleAFeeRiskModel;
    medium: ScheduleAFeeRiskModel;
    high: ScheduleAFeeRiskModel;
  };
};

export type ScheduleAView = ViewBase<ScheduleA>;

export const selectScheduleAView: ViewSelector<ScheduleAView, ScheduleA> = snapshot => {
  const scheduleA: ScheduleAView = {
    ...defaultViewSelector(snapshot),
  };
  return scheduleA;
};
