import {
  ApplicationDisputePayload,
  ApplicationDisputeResult,
  BoardAccountPayload,
  BoardAccountResult,
  CommandNames,
  CreateAgentPayload,
  CreateAgentResult,
  CreateAnnouncementPayload,
  CreateAnnouncementResult,
  CreateAssociationBetweenApplicationsPayload,
  CreateAssociationBetweenApplicationsResult,
  CreateLeadPayload,
  CreateLeadResult,
  CreateUserPayload,
  CreateUserResult,
  DownloadPdfPayload,
  DownloadPdfResult,
  EncryptFieldResult,
  ExecuteUtilityPayload,
  ExecuteUtilityResult,
  Firebase,
  GeneratePdfPayload,
  GeneratePdfResult,
  GetAlgoliaSearchKeyResult,
  GetDocumentsUploadTokenPayload,
  GetDocumentsUploadTokenResult,
  GetPortalTokenPayload,
  GetPortalTokenResult,
  GetSiteJumpTokenPayload,
  GetSiteJumpTokenResult,
  GetTransferAuthTokenPayload,
  GetTransferAuthTokenResult,
  GetUsersProfilesPayload,
  GetUsersProfilesResult,
  RegisterNowEmailPayload,
  RegisterNowEmailResult,
  RemoveAssociationBetweenApplicationsPayload,
  RemoveAssociationBetweenApplicationsResult,
  ResetPasswordPayload,
  ResetPasswordResult,
  RunResidualCalculationsPayload,
  RunResidualCalculationsResult,
  RunScorecardServicePayload,
  RunScorecardServiceResult,
  SendAppNotificationPayload,
  SendAppNotificationResult,
  SetDispositionPayload,
  SetDispositionResult,
  SetProfileImagePayload,
  SetProfileImageResult,
  ToggleGroupActivePayload,
  ToggleGroupActiveResult,
  ToggleUserActivePayload,
  ToggleUserActiveResult,
  TransferApplicationPayload,
  TransferApplicationResult,
  ValidateMerchantForRegistrationPayload,
  ValidateMerchantForRegistrationResult,
} from '@ozark/common';
import {CloneApplicationRequestParams} from '@ozark/functions/src/functions/callable/cloneApplication';
import {
  ArchivateAttachmentsPayload,
  ArchivateAttachmentsResult,
} from '@ozark/functions/src/functions/callable/dispatch_ArchivateAttachments';
import {
  CreateDownloadFilesRequestPayload,
  CreateDownloadFilesRequestResult,
} from '@ozark/functions/src/functions/callable/dispatch_CreateDownloadFilesRequest';
import {
  CreateMerchantUserPayload,
  CreateMerchantUserResult,
} from '@ozark/functions/src/functions/callable/dispatch_CreateMerchantUser';
import {
  CreateResourcePayload,
  CreateResourceResult,
} from '@ozark/functions/src/functions/callable/dispatch_CreateResource';
import {
  DeleteMerchantUserPayload,
  DeleteMerchantUserResult,
} from '@ozark/functions/src/functions/callable/dispatch_DeleteMerchantUser';
import {
  DocumentsUploadFinicityGetAccountsPayload,
  DocumentsUploadFinicityGetAccountsResult,
} from '@ozark/functions/src/functions/callable/dispatch_DocumentsUploadFinicityGetAccounts';
import {
  DocumentsUploadFinicityGetAchDetailPayload,
  DocumentsUploadFinicityGetAchDetailResult,
} from '@ozark/functions/src/functions/callable/dispatch_DocumentsUploadFinicityGetAchDetail';
import {
  DocumentsUploadFinicityGetInstitutionPayload,
  DocumentsUploadFinicityGetInstitutionResult,
} from '@ozark/functions/src/functions/callable/dispatch_DocumentsUploadFinicityGetInstitution';
import {
  DocumentsUploadFinicityLinkBankAccountPayload,
  DocumentsUploadFinicityLinkBankAccountResult,
} from '@ozark/functions/src/functions/callable/dispatch_DocumentsUploadFinicityLinkBankAccount';
import {
  DocumentsUploadGenerateFinicityLinkTokenPayload,
  DocumentsUploadGenerateFinicityLinkTokenResult,
} from '@ozark/functions/src/functions/callable/dispatch_DocumentsUploadGenerateFinicityLinkToken';
import {
  DocumentsUploadGeneratePlaidLinkTokenPayload,
  DocumentsUploadGeneratePlaidLinkTokenResult,
} from '@ozark/functions/src/functions/callable/dispatch_DocumentsUploadGeneratePlaidLinkToken';
import {
  DocumentsUploadPersonaGetDataPayload,
  DocumentsUploadPersonaGetDataResult,
} from '@ozark/functions/src/functions/callable/dispatch_DocumentsUploadPersonaGetData';
import {
  DocumentsUploadPersonaSaveDataPayload,
  DocumentsUploadPersonaSaveDataResult,
} from '@ozark/functions/src/functions/callable/dispatch_DocumentsUploadPersonaSaveData';
import {
  DocumentsUploadSelectFinicityAccountPayload,
  DocumentsUploadSelectFinicityAccountResult,
} from '@ozark/functions/src/functions/callable/dispatch_DocumentsUploadSelectFinicityAccount';
import {
  DocumentsUploadSelectPlaidAccountPayload,
  DocumentsUploadSelectPlaidAccountResult,
} from '@ozark/functions/src/functions/callable/dispatch_DocumentsUploadSelectPlaidAccount';
import {
  GetResidualAdjustmentsPayload,
  GetResidualAdjustmentsResult,
} from '@ozark/functions/src/functions/callable/dispatch_GetResidualAdjustments';
import {
  GetVerifiedDocumentsUploadTokenPayload,
  GetVerifiedDocumentsUploadTokenResult,
} from '@ozark/functions/src/functions/callable/dispatch_GetVerifiedDocumentsUploadToken';
import {
  MarkAllNotificationsAsReadPayload,
  MarkAllNotificationsAsReadResult,
} from '@ozark/functions/src/functions/callable/dispatch_MarkAllNotificationsAsRead';
import {
  MoveAttachmentsPayload,
  MoveAttachmentsResult,
} from '@ozark/functions/src/functions/callable/dispatch_MoveAttachments';
import {
  RegisterMerchantUserPayload,
  RegisterMerchantUserResult,
} from '@ozark/functions/src/functions/callable/dispatch_RegisterMerchantUser';
import {
  SaveResidualManualAdjustmentsPayload,
  SaveResidualManualAdjustmentsResult,
} from '@ozark/functions/src/functions/callable/dispatch_SaveResidualManualAdjustments';
import {SendAgentLogInEmailResult} from '@ozark/functions/src/functions/callable/dispatch_SendAgentLogInEmail';
import {SendMerchantLogInEmailResult} from '@ozark/functions/src/functions/callable/dispatch_SendMerchantLogInEmail';
import {
  SendMfaCodePayload,
  SendMfaCodeResult,
} from '@ozark/functions/src/functions/callable/dispatch_SendMfaCode';
import {
  UpdateResourcePayload,
  UpdateResourceResult,
} from '@ozark/functions/src/functions/callable/dispatch_UpdateResource';
import {
  UpdateResourceOrderPayload,
  UpdateResourceOrderResult,
} from '@ozark/functions/src/functions/callable/dispatch_UpdateResourceOrder';
import {
  WelcomeEmailPayload,
  WelcomeEmailResult,
} from '@ozark/functions/src/functions/callable/dispatch_WelcomeEmail';
import {
  WelcomeEmailWithResetPasswordPayload,
  WelcomeEmailWithResetPasswordResult,
} from '@ozark/functions/src/functions/callable/dispatch_WelcomeEmailWithResetPassword';
import {useCallback} from 'react';
import {
  ApplicationTransferToGroupPayload,
  ApplicationTransferToGroupResult,
} from '../../functions/src/functions/callable/dispatch_ApplicationTransferToGroup';
import {
  ApplicationUnlinkFinicityPayload,
  ApplicationUnlinkFinicityResult,
} from '../../functions/src/functions/callable/dispatch_ApplicationUnlinkFinicity';
import {
  ConfirmMfaCodePayload,
  ConfirmMfaCodeResult,
} from '../../functions/src/functions/callable/dispatch_confirmMfaCode';
import {
  DecryptApplicationFieldPayload,
  DecryptApplicationFieldResult,
} from '../../functions/src/functions/callable/dispatch_DecryptText';
import {
  GetResidualGroupAdjustmentsPayload,
  GetResidualGroupAdjustmentsResult,
} from '../../functions/src/functions/callable/dispatch_GetResidualGroupAdjustments';
import {
  MfaLoginUsePassPayload,
  MfaLoginUsePassResult,
} from '../../functions/src/functions/callable/dispatch_MfaLoginUsePass';
import {
  ResetMerchantRegistrationAttemptsPayload,
  ResetMerchantRegistrationAttemptsResult,
} from '../../functions/src/functions/callable/dispatch_ResetMerchantRegistrationAttempts';

const createCallableWrapper =
  <TPayload, TResult>(command: CommandNames) =>
  async (payload?: TPayload): Promise<TResult> => {
    const callFunction = Firebase.functions.httpsCallable('dispatchCallable');
    const result: TResult = await (async () => {
      try {
        const {data} = await callFunction({command, payload});
        return data;
      } catch (err: any) {
        return {
          status: 'error',
          message: err.message,
        };
      }
    })();
    return result;
  };

export const useCallable = () => {
  const cloneApplication = async (params: CloneApplicationRequestParams) => {
    const {data} = await Firebase.functions.httpsCallable('cloneApplication')(params);
    return data;
  };

  const encryptField = useCallback(async (plainText: string) => {
    const {data} = await Firebase.functions.httpsCallable('encryptField')({plainText});
    if (!data) return null;
    return data as EncryptFieldResult;
  }, []);

  const getPersonaIdConfig = useCallback(async () => {
    return await Firebase.functions.httpsCallable('getPersonaIdConfig')();
  }, []);

  const dispatchable = {
    [CommandNames.registerNowEmail]: createCallableWrapper<
      RegisterNowEmailPayload,
      RegisterNowEmailResult
    >(CommandNames.registerNowEmail),
    [CommandNames.welcomeEmail]: createCallableWrapper<WelcomeEmailPayload, WelcomeEmailResult>(
      CommandNames.welcomeEmail
    ),
    [CommandNames.welcomeEmailWithResetPassword]: createCallableWrapper<
      WelcomeEmailWithResetPasswordPayload,
      WelcomeEmailWithResetPasswordResult
    >(CommandNames.welcomeEmailWithResetPassword),
    [CommandNames.toggleUserActive]: createCallableWrapper<
      ToggleUserActivePayload,
      ToggleUserActiveResult
    >(CommandNames.toggleUserActive),
    [CommandNames.setProfileImage]: createCallableWrapper<
      SetProfileImagePayload,
      SetProfileImageResult
    >(CommandNames.setProfileImage),
    [CommandNames.createUser]: createCallableWrapper<CreateUserPayload, CreateUserResult>(
      CommandNames.createUser
    ),
    [CommandNames.resetPassword]: createCallableWrapper<ResetPasswordPayload, ResetPasswordResult>(
      CommandNames.resetPassword
    ),
    [CommandNames.createResource]: createCallableWrapper<
      CreateResourcePayload,
      CreateResourceResult
    >(CommandNames.createResource),
    [CommandNames.updateResource]: createCallableWrapper<
      UpdateResourcePayload,
      UpdateResourceResult
    >(CommandNames.updateResource),
    [CommandNames.updateResourceOrder]: createCallableWrapper<
      UpdateResourceOrderPayload,
      UpdateResourceOrderResult
    >(CommandNames.updateResourceOrder),
    [CommandNames.createLead]: createCallableWrapper<CreateLeadPayload, CreateLeadResult>(
      CommandNames.createLead
    ),
    [CommandNames.createAssociationBetweenApplications]: createCallableWrapper<
      CreateAssociationBetweenApplicationsPayload,
      CreateAssociationBetweenApplicationsResult
    >(CommandNames.createAssociationBetweenApplications),
    [CommandNames.removeAssociationBetweenApplications]: createCallableWrapper<
      RemoveAssociationBetweenApplicationsPayload,
      RemoveAssociationBetweenApplicationsResult
    >(CommandNames.removeAssociationBetweenApplications),
    [CommandNames.setDisposition]: createCallableWrapper<
      SetDispositionPayload,
      SetDispositionResult
    >(CommandNames.setDisposition),
    [CommandNames.getPortalToken]: createCallableWrapper<
      GetPortalTokenPayload,
      GetPortalTokenResult
    >(CommandNames.getPortalToken),
    [CommandNames.generatePdf]: createCallableWrapper<GeneratePdfPayload, GeneratePdfResult>(
      CommandNames.generatePdf
    ),
    [CommandNames.downloadPdf]: createCallableWrapper<DownloadPdfPayload, DownloadPdfResult>(
      CommandNames.downloadPdf
    ),
    [CommandNames.applicationUnlinkFinicity]: createCallableWrapper<
      ApplicationUnlinkFinicityPayload,
      ApplicationUnlinkFinicityResult
    >(CommandNames.applicationUnlinkFinicity),
    [CommandNames.getAlgoliaSearchKey]: createCallableWrapper<
      'applications' | 'leads' | 'tickets',
      GetAlgoliaSearchKeyResult
    >(CommandNames.getAlgoliaSearchKey),

    [CommandNames.sendApplicationDispute]: createCallableWrapper<
      ApplicationDisputePayload,
      ApplicationDisputeResult
    >(CommandNames.sendApplicationDispute),
    [CommandNames.createAnnouncement]: createCallableWrapper<
      CreateAnnouncementPayload,
      CreateAnnouncementResult
    >(CommandNames.createAnnouncement),
    [CommandNames.getResidualAdjustments]: createCallableWrapper<
      GetResidualAdjustmentsPayload,
      GetResidualAdjustmentsResult
    >(CommandNames.getResidualAdjustments),
    [CommandNames.saveResidualManualAdjustments]: createCallableWrapper<
      SaveResidualManualAdjustmentsPayload,
      SaveResidualManualAdjustmentsResult
    >(CommandNames.saveResidualManualAdjustments),
    [CommandNames.getResidualGroupAdjustments]: createCallableWrapper<
      GetResidualGroupAdjustmentsPayload,
      GetResidualGroupAdjustmentsResult
    >(CommandNames.getResidualGroupAdjustments),
    [CommandNames.sendAppNotification]: createCallableWrapper<
      SendAppNotificationPayload,
      SendAppNotificationResult
    >(CommandNames.sendAppNotification),
    [CommandNames.toggleGroupActive]: createCallableWrapper<
      ToggleGroupActivePayload,
      ToggleGroupActiveResult
    >(CommandNames.toggleGroupActive),
    [CommandNames.boardAccount]: createCallableWrapper<BoardAccountPayload, BoardAccountResult>(
      CommandNames.boardAccount
    ),
    [CommandNames.createAgent]: createCallableWrapper<CreateAgentPayload, CreateAgentResult>(
      CommandNames.createAgent
    ),
    [CommandNames.sendAgentLogInEmail]: createCallableWrapper<string, SendAgentLogInEmailResult>(
      CommandNames.sendAgentLogInEmail
    ),
    [CommandNames.sendMerchantLogInEmail]: createCallableWrapper<
      string,
      SendMerchantLogInEmailResult
    >(CommandNames.sendMerchantLogInEmail),
    [CommandNames.validateMerchantForRegistration]: createCallableWrapper<
      ValidateMerchantForRegistrationPayload,
      ValidateMerchantForRegistrationResult
    >(CommandNames.validateMerchantForRegistration),
    [CommandNames.createMerchantUser]: createCallableWrapper<
      CreateMerchantUserPayload,
      CreateMerchantUserResult
    >(CommandNames.createMerchantUser),
    [CommandNames.registerMerchantUser]: createCallableWrapper<
      RegisterMerchantUserPayload,
      RegisterMerchantUserResult
    >(CommandNames.registerMerchantUser),
    [CommandNames.deleteMerchantUser]: createCallableWrapper<
      DeleteMerchantUserPayload,
      DeleteMerchantUserResult
    >(CommandNames.deleteMerchantUser),
    [CommandNames.decryptApplicationField]: createCallableWrapper<
      DecryptApplicationFieldPayload,
      DecryptApplicationFieldResult
    >(CommandNames.decryptApplicationField),
    [CommandNames.transferApplication]: createCallableWrapper<
      TransferApplicationPayload,
      TransferApplicationResult
    >(CommandNames.transferApplication),
    [CommandNames.getTransferAuthToken]: createCallableWrapper<
      GetTransferAuthTokenPayload,
      GetTransferAuthTokenResult
    >(CommandNames.getTransferAuthToken),
    [CommandNames.executeUtility]: createCallableWrapper<
      ExecuteUtilityPayload,
      ExecuteUtilityResult
    >(CommandNames.executeUtility),
    [CommandNames.runScorecardService]: createCallableWrapper<
      RunScorecardServicePayload,
      RunScorecardServiceResult
    >(CommandNames.runScorecardService),
    [CommandNames.runResidualCalculations]: createCallableWrapper<
      RunResidualCalculationsPayload,
      RunResidualCalculationsResult
    >(CommandNames.runResidualCalculations),
    [CommandNames.getSiteJumpToken]: createCallableWrapper<
      GetSiteJumpTokenPayload,
      GetSiteJumpTokenResult
    >(CommandNames.getSiteJumpToken),
    [CommandNames.getDocumentsUploadToken]: createCallableWrapper<
      GetDocumentsUploadTokenPayload,
      GetDocumentsUploadTokenResult
    >(CommandNames.getDocumentsUploadToken),
    [CommandNames.getVerifiedDocumentsUploadToken]: createCallableWrapper<
      GetVerifiedDocumentsUploadTokenPayload,
      GetVerifiedDocumentsUploadTokenResult
    >(CommandNames.getVerifiedDocumentsUploadToken),
    [CommandNames.getDocumentsUploadPlaidLinkToken]: createCallableWrapper<
      DocumentsUploadGeneratePlaidLinkTokenPayload,
      DocumentsUploadGeneratePlaidLinkTokenResult
    >(CommandNames.getDocumentsUploadPlaidLinkToken),
    [CommandNames.getDocumentsUploadFinicityLinkToken]: createCallableWrapper<
      DocumentsUploadGenerateFinicityLinkTokenPayload,
      DocumentsUploadGenerateFinicityLinkTokenResult
    >(CommandNames.getDocumentsUploadFinicityLinkToken),
    [CommandNames.getDocumentsUploadFinicityAchDetail]: createCallableWrapper<
      DocumentsUploadFinicityGetAchDetailPayload,
      DocumentsUploadFinicityGetAchDetailResult
    >(CommandNames.getDocumentsUploadFinicityAchDetail),
    [CommandNames.getDocumentsUploadFinicityAccounts]: createCallableWrapper<
      DocumentsUploadFinicityGetAccountsPayload,
      DocumentsUploadFinicityGetAccountsResult
    >(CommandNames.getDocumentsUploadFinicityAccounts),
    [CommandNames.getDocumentsUploadFinicityInstitution]: createCallableWrapper<
      DocumentsUploadFinicityGetInstitutionPayload,
      DocumentsUploadFinicityGetInstitutionResult
    >(CommandNames.getDocumentsUploadFinicityInstitution),
    [CommandNames.selectDocumentsUploadFinicityLinkBankAccount]: createCallableWrapper<
      DocumentsUploadFinicityLinkBankAccountPayload,
      DocumentsUploadFinicityLinkBankAccountResult
    >(CommandNames.selectDocumentsUploadFinicityLinkBankAccount),
    [CommandNames.getDocumentsUploadPersonaGetData]: createCallableWrapper<
      DocumentsUploadPersonaGetDataPayload,
      DocumentsUploadPersonaGetDataResult
    >(CommandNames.getDocumentsUploadPersonaGetData),
    [CommandNames.documentsUploadPersonaSaveData]: createCallableWrapper<
      DocumentsUploadPersonaSaveDataPayload,
      DocumentsUploadPersonaSaveDataResult
    >(CommandNames.documentsUploadPersonaSaveData),
    [CommandNames.selectDocumentsUploadPlaidAccount]: createCallableWrapper<
      DocumentsUploadSelectPlaidAccountPayload,
      DocumentsUploadSelectPlaidAccountResult
    >(CommandNames.selectDocumentsUploadPlaidAccount),
    [CommandNames.selectDocumentsUploadFinicityAccount]: createCallableWrapper<
      DocumentsUploadSelectFinicityAccountPayload,
      DocumentsUploadSelectFinicityAccountResult
    >(CommandNames.selectDocumentsUploadFinicityAccount),
    [CommandNames.markAllNotificationsAsRead]: createCallableWrapper<
      MarkAllNotificationsAsReadPayload,
      MarkAllNotificationsAsReadResult
    >(CommandNames.markAllNotificationsAsRead),
    [CommandNames.moveAttachments]: createCallableWrapper<
      MoveAttachmentsPayload,
      MoveAttachmentsResult
    >(CommandNames.moveAttachments),
    [CommandNames.createDownloadFilesRequest]: createCallableWrapper<
      CreateDownloadFilesRequestPayload,
      CreateDownloadFilesRequestResult
    >(CommandNames.createDownloadFilesRequest),
    [CommandNames.sendMfaCode]: createCallableWrapper<SendMfaCodePayload, SendMfaCodeResult>(
      CommandNames.sendMfaCode
    ),
    [CommandNames.confirmMfaCode]: createCallableWrapper<
      ConfirmMfaCodePayload,
      ConfirmMfaCodeResult
    >(CommandNames.confirmMfaCode),
    [CommandNames.mfaLoginUsePass]: createCallableWrapper<
      MfaLoginUsePassPayload,
      MfaLoginUsePassResult
    >(CommandNames.mfaLoginUsePass),
    [CommandNames.archivateAttachments]: createCallableWrapper<
      ArchivateAttachmentsPayload,
      ArchivateAttachmentsResult
    >(CommandNames.archivateAttachments),
    [CommandNames.applicationTransferToGroup]: createCallableWrapper<
      ApplicationTransferToGroupPayload,
      ApplicationTransferToGroupResult
    >(CommandNames.applicationTransferToGroup),
    [CommandNames.getUsersProfiles]: createCallableWrapper<
      GetUsersProfilesPayload,
      GetUsersProfilesResult
    >(CommandNames.getUsersProfiles),
    [CommandNames.resetMerchantRegistrationAttempts]: createCallableWrapper<
      ResetMerchantRegistrationAttemptsPayload,
      ResetMerchantRegistrationAttemptsResult
    >(CommandNames.resetMerchantRegistrationAttempts),
  };

  return {
    cloneApplication,
    encryptField,
    getPersonaIdConfig,
    ...dispatchable,
  };
};
