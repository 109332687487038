import {yupResolver} from '@hookform/resolvers/yup';
import {
  AccordionActions,
  Alert,
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  InputAdornment,
  Paper,
  Switch,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  copyWithoutRef,
  RiskLevel,
  ScheduleAView,
  SplitMethodType,
  useDefaultScheduleA,
  useNotification,
} from '@ozark/common';
import {ConfirmationDialog, Loading, TextField} from '@ozark/common/components';
import {emptyStringToNull} from '@ozark/common/helpers/schemaValidationHelpers';
import {FeeCategories} from '@ozark/functions/src/constants';
import camelcase from 'lodash/camelCase';
import startcase from 'lodash/startCase';
import {Fragment, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {AgentCalculationMethod} from './AgentCalculationMethod';

const fields = FeeCategories;

const createRiskLevelModelSchema = (
  allowNegatives: boolean,
  riskLevel: RiskLevel,
  splitMethod?: SplitMethodType
) => {
  const arraysOfKeys = Object.values(fields)
    .map(e => Object.keys(e))
    .flat();
  const yupObject = arraysOfKeys.reduce((previousValue: any, currentValue: string) => {
    return {
      ...previousValue,
      [currentValue]: [
        (y: any) => y.number(),
        (y: any) => {
          if (currentValue === 'monthlyProcessingVolumePercent') {
            if (splitMethod === SplitMethodType.direct) {
              return y.when(
                '$monthlyProcessingVolumePercent',
                (monthlyProcessingVolumePercent: any, schema: any) => {
                  const maxValue = monthlyProcessingVolumePercent[riskLevel];
                  return maxValue ? schema.max(maxValue, `Cannot be greater than ${maxValue}`) : y;
                }
              );
            }
            return y;
          }
          if (allowNegatives) {
            return y;
          }
          return y.min(0, 'Value cannot be negative.');
        },
        (y: any) => y.transform(emptyStringToNull),
        (y: any) => y.required('Value is Required'),
      ].reduce((yupObject, applyRuleFunction) => {
        return applyRuleFunction(yupObject);
      }, yup),
    };
  }, {});
  return yupObject;
};

type RiskLevelSchemas = {
  [key in RiskLevel]: any;
};

const createRiskModelSchema = (
  allowNegatives: boolean,
  splitMethod: SplitMethodType | undefined
) => {
  const result: RiskLevelSchemas = {} as RiskLevelSchemas;
  for (const riskLevel of Object.values(RiskLevel)) {
    result[riskLevel] = yup.object(
      createRiskLevelModelSchema(allowNegatives, riskLevel, splitMethod)
    );
  }
  return result;
};
const createSchema = (allowNegatives: boolean) =>
  yup.object().shape({
    riskModels: yup
      .object()
      .required()
      .when('splitMethod', (splitMethod: SplitMethodType | undefined) => {
        return yup.object().shape(createRiskModelSchema(allowNegatives, splitMethod));
      }),
  });

export type ScheduleADefaultSettings = {
  id?: string;
  type?: 'agent' | 'group';
};

export type ScheduleALossCheckResult = {
  field: string;
  data: {
    [_ in RiskLevel]: boolean;
  };
};

type Props = {
  document?: ScheduleAView;
  parentScheduleAParams?: ScheduleADefaultSettings;
  set?: any;
  applyDefaults?: boolean;
  readonly?: boolean;
  allowNegatives?: boolean;
  riskTogglesEnabled?: boolean;
  displayCalculationMethod?: boolean;
  disableAgentCalculationMethod?: boolean;
};

export const ScheduleA = ({
  document,
  parentScheduleAParams,
  set,
  applyDefaults = false,
  readonly = false,
  allowNegatives = false,
  riskTogglesEnabled = true,
  displayCalculationMethod = false,
  disableAgentCalculationMethod = false,
}: Props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState<(() => Promise<void>) | null>(null);
  const [scheduleALossCheck, setScheduleALossCheck] = useState<{
    hasLoss: boolean;
    data?: ScheduleALossCheckResult[];
  }>({hasLoss: false});
  const [defaultValuesAlertInfo, setDefaultValuesAlertInfo] = useState<{
    display: boolean;
    text?: string;
  }>(
    !document && applyDefaults && !parentScheduleAParams
      ? {
          display: true,
          text: 'Default values have been applied. You must still save this schedule A by clicking the button below.',
        }
      : {display: false}
  );
  const {document: parentScheduleA} = useDefaultScheduleA(parentScheduleAParams);

  const formMethods = useForm<ScheduleAView>({
    resolver: yupResolver(createSchema(allowNegatives)) as any,
    context: {
      monthlyProcessingVolumePercent: {
        [RiskLevel.lowRisk]:
          parentScheduleA.data?.riskModels[RiskLevel.lowRisk]?.monthlyProcessingVolumePercent,
        [RiskLevel.mediumRisk]:
          parentScheduleA.data?.riskModels[RiskLevel.mediumRisk]?.monthlyProcessingVolumePercent,
        [RiskLevel.highRisk]:
          parentScheduleA.data?.riskModels[RiskLevel.highRisk]?.monthlyProcessingVolumePercent,
      },
    },
    defaultValues: {...copyWithoutRef(document)},
  });

  const {formState, reset, handleSubmit} = formMethods;
  const {isDirty} = formState;

  const getDefaultScheduleATitle =
    parentScheduleAParams?.type === 'group' ? 'group' : 'master agent';
  const defaultParentAppliedMessage = `Default ${getDefaultScheduleATitle} schedule A values have been applied. You must still save this schedule A by clicking the button below.`;

  useEffect(() => {
    if (parentScheduleA.promised || document || !applyDefaults) return;

    // parent schedule A
    setDefaultValuesAlertInfo({
      display: true,
      text: parentScheduleA.data
        ? defaultParentAppliedMessage
        : `Cannot set default values. No ${getDefaultScheduleATitle} schedule A exists.`,
    });
  }, [document, parentScheduleA]);

  useEffect(() => {
    if (!document || !parentScheduleA?.data) return;

    const arrayOfFees = Object.values(fields)
      .map(e => Object.keys(e))
      .flat();
    const riskLevels = Object.values(RiskLevel);

    let hasPotentialLoss = false;
    const lossCheckData = arrayOfFees.map(feeKey => {
      const levelsCheck = {};
      riskLevels.forEach(riskLevel => {
        const defaultValue = (parentScheduleA.data?.riskModels as any)[riskLevel]?.[feeKey];
        const currentValue = (document.riskModels as any)[riskLevel]?.[feeKey];
        const hasLoss = currentValue < defaultValue;
        if (hasLoss) {
          hasPotentialLoss = true;
        }
        (levelsCheck as any)[riskLevel] = hasLoss;
      });

      return {
        field: feeKey,
        data: levelsCheck,
      } as ScheduleALossCheckResult;
    });

    const scheduleALossCheckResult = {
      hasLoss: hasPotentialLoss,
      data: lossCheckData,
    };

    setScheduleALossCheck(scheduleALossCheckResult);
  }, [document, parentScheduleA]);

  const showNotification = useNotification();

  const onSuccess = async (data: any) => {
    setLoading(true);
    try {
      await set(data);
      setDefaultValuesAlertInfo({
        display: false,
      });

      reset(data);
      showNotification('success', 'Schedule A successfully updated.');
    } catch (err) {
      showNotification('error', 'Failed to update.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopySheduleA = async () => {
    reset({...parentScheduleA.data});
    setDefaultValuesAlertInfo({
      display: true,
      text: defaultParentAppliedMessage,
    });
    // we can't have the potential loss after copying the parent schedule A
    setScheduleALossCheck({
      hasLoss: false,
    });
  };

  const watch = {
    'riskModels.low.monthlyProcessingVolumePercent': formMethods.watch(
      'riskModels.low.monthlyProcessingVolumePercent'
    ) as number,
    'riskModels.medium.monthlyProcessingVolumePercent': formMethods.watch(
      'riskModels.medium.monthlyProcessingVolumePercent'
    ) as number,
    'riskModels.high.monthlyProcessingVolumePercent': formMethods.watch(
      'riskModels.high.monthlyProcessingVolumePercent'
    ) as number,
  } as any;

  const handleToggleRiskChange =
    (riskLevel: RiskLevel) => (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const value = (checked ? 0 : -1) as any;
      formMethods.setValue(`riskModels.${riskLevel}.monthlyProcessingVolumePercent`, value, {
        shouldDirty: true,
      });
    };

  if (parentScheduleAParams && parentScheduleA.promised) {
    return <Loading />;
  }

  return (
    <FormProvider {...formMethods}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          {parentScheduleA.data && (
            <>
              <Grid item xs={12} className={classes.actionBar}>
                <Button
                  id="copyScheduleAButton"
                  name="copyScheduleA"
                  onClick={() => setConfirmationAction(() => handleCopySheduleA)}
                  variant="outlined"
                  color="secondary"
                  disabled={readonly}
                >
                  {`Copy ${getDefaultScheduleATitle} Schedule A`}
                </Button>
              </Grid>
            </>
          )}
          {displayCalculationMethod && (
            <Grid item xs={12}>
              <AgentCalculationMethod
                errors={formMethods.formState.errors}
                control={formMethods.control}
                disabled={
                  disableAgentCalculationMethod ||
                  (parentScheduleAParams?.type === 'agent' &&
                    parentScheduleA.data?.splitMethod !== SplitMethodType.direct)
                }
              />
            </Grid>
          )}
          {scheduleALossCheck.hasLoss && (
            <>
              <Grid item xs={12}>
                <Alert severity="warning">
                  {`Schedule A fee value is less than the parent. To avoid potential loss copy the ${getDefaultScheduleATitle} schedule A.`}
                </Alert>
              </Grid>
            </>
          )}
          {defaultValuesAlertInfo.display && (
            <>
              <Grid item xs={12}>
                <Alert severity="info">{defaultValuesAlertInfo.text}</Alert>
              </Grid>
            </>
          )}
          <Grid item xs={3}>
            <Box>
              <Typography variant="caption">Current Version: {document?.version}</Typography>
            </Box>
          </Grid>
          {Object.values(RiskLevel).map(riskLevel => (
            <Grid key={`riskLevel.${riskLevel}`} item xs={3}>
              <Box pt={1}>
                <Typography align="center" variant="h6">
                  {startcase(riskLevel)} Risk{' '}
                  {riskTogglesEnabled && (
                    <Switch
                      checked={watch[`riskModels.${riskLevel}.monthlyProcessingVolumePercent`] >= 0}
                      onChange={handleToggleRiskChange(riskLevel)}
                      color="primary"
                      disabled={readonly}
                      inputProps={{'aria-label': 'primary checkbox'}}
                    />
                  )}
                </Typography>
              </Box>
            </Grid>
          ))}
          {Object.keys(fields).map((category: string) => {
            const riskFieldObject = (fields as any)[category];
            const categoryFieldKeys = Object.keys(riskFieldObject);
            return (
              <Grid key={`category.${category}`} item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <div className={classes.categoryTitleWrapper}>
                      <Typography className={classes.categoryTitle} variant="body1" gutterBottom>
                        {category}
                      </Typography>
                    </div>
                  </Grid>

                  {categoryFieldKeys.map(fieldKey => {
                    const fieldObject = (fields as any)[category][fieldKey];
                    return Object.values(RiskLevel).map((riskLevel, index) => {
                      const riskLevelKey = camelcase(riskLevel); // Low / Medium / High
                      const isRiskEnabled =
                        watch[`riskModels.${riskLevel}.monthlyProcessingVolumePercent`] >= 0;
                      return (
                        <Fragment key={`${fieldKey}-${riskLevel}`}>
                          {index === 0 && (
                            <Grid item xs={3}>
                              <Box display="flex" alignItems="center" height="100%">
                                <Typography variant="body1">{fieldObject.label}</Typography>
                              </Box>
                            </Grid>
                          )}
                          <Grid item xs={3}>
                            <TextField
                              name={`riskModels.${riskLevelKey}.${fieldKey}`}
                              errors={formMethods.formState.errors}
                              control={formMethods.control}
                              type="number"
                              defaultValue={
                                !document &&
                                applyDefaults &&
                                (parentScheduleA.data
                                  ? // display parent schedule A if exist
                                    (parentScheduleA.data?.riskModels as any)[riskLevel]?.[fieldKey]
                                  : !parentScheduleAParams
                                  ? // display hardcoded fees if parent schedule A params are not exist
                                    fieldObject?.defaultValues?.[riskLevel]
                                  : false)
                              }
                              disabled={readonly || !isRiskEnabled}
                              style={{display: !isRiskEnabled ? 'none' : 'inherit'}}
                              InputProps={{
                                startAdornment:
                                  fieldObject.type === '$' ? (
                                    <InputAdornment position="start">$</InputAdornment>
                                  ) : null,
                                endAdornment:
                                  fieldObject.type === '%' ? (
                                    <InputAdornment position="end">%</InputAdornment>
                                  ) : null,
                                classes: {
                                  notchedOutline:
                                    scheduleALossCheck.hasLoss &&
                                    scheduleALossCheck.data?.find(x => x.field === fieldKey)?.data[
                                      riskLevel
                                    ] === true
                                      ? classes.potentialLoss
                                      : '',
                                },
                              }}
                            />
                          </Grid>
                        </Fragment>
                      );
                    });
                  })}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        {!readonly && (
          <Fade in={isDirty || defaultValuesAlertInfo.display} unmountOnExit={false}>
            <AccordionActions>
              <Box mt={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmit(onSuccess)}
                  disabled={loading}
                >
                  {loading && <CircularProgress className={classes.buttonProgress} size={24} />}Save
                  Changes
                </Button>
              </Box>
            </AccordionActions>
          </Fade>
        )}
        <ConfirmationDialog
          title="Confirmation"
          maxWidth="sm"
          message={`Are you sure you want to copy the ${getDefaultScheduleATitle} schedule A?`}
          onClose={() => setConfirmationAction(null)}
          onConfirm={confirmationAction}
        />
      </Paper>
    </FormProvider>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 2, 2, 2),
    },
    potentialLoss: {
      borderColor: 'red',
    },
    categoryTitle: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(2, 0, 2),
      padding: theme.spacing(2, 1),
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      '& > *': {
        marginRight: theme.spacing(1),
      },
    },
    categoryTitleWrapper: {
      marginTop: theme.spacing(2),
    },
    actionBar: {
      direction: 'rtl',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);
