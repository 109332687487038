import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {IconButton, Link, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useGroup, useGroupScheduleA, UserRoles} from '@ozark/common';
import {GroupEdit, Loading, ScheduleA, Title} from '@ozark/common/components';
import React, {useState} from 'react';
import {Redirect, useHistory, useParams} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {Tab, Tabs} from '../Tabs';
import {SubmittedPageSettings} from './SubmittedPageSettings';

enum GroupTab {
  Edit,
  ScheduleA,
  SubmittedPageSettings,
  Agents,
}

const GroupDetails = () => {
  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('lg'));
  const [tab, setTab] = useState(0);
  const {id} = useParams<{id: string}>();
  const {document: group} = useGroup(id);
  const {authProfile, isUserAdmin} = useStore();

  const {document: scheduleA, addNewVersion: setScheduleA} = useGroupScheduleA(id);

  const isAdmin = isUserAdmin();
  const isAgentSupport = authProfile?.data?.role === UserRoles.agentSupport;

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
  };

  if (group.promised || authProfile.promised) return <Loading />;

  if (!group.data) return <Redirect to={ROUTES.GROUPS} />;

  return (
    <div>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => history.push(ROUTES.GROUPS)}
                >
                  Groups
                </Link>,
                <Typography variant="body1">{group.data.name}</Typography>,
              ]
        }
      >
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab label="Edit Group" value={GroupTab.Edit} />
          <Tab
            label="Application Submitted Page"
            value={GroupTab.SubmittedPageSettings}
            disabled={!(isAdmin || isAgentSupport)}
          />
          <Tab
            label="Schedule A"
            value={GroupTab.ScheduleA}
            disabled={!(isAdmin || isAgentSupport)}
          />
          <Tab label="Agents" value={GroupTab.Agents} disabled />
        </Tabs>
      </Title>
      {tab === GroupTab.Edit && <GroupEdit groupId={group.data.id} isAuthUserAdmin={isAdmin} />}
      {tab === GroupTab.SubmittedPageSettings && <SubmittedPageSettings group={group.data} />}
      {tab === GroupTab.ScheduleA && !scheduleA.promised && (
        <ScheduleA
          document={scheduleA.data}
          set={setScheduleA}
          applyDefaults={true}
          allowNegatives={isAdmin}
        />
      )}
    </div>
  );
};

export default GroupDetails;
