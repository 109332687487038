import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BuildIcon from '@mui/icons-material/Build';
import BusinessIcon from '@mui/icons-material/Business';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GroupIcon from '@mui/icons-material/Group';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import {Box, Button, CardMedia, Grid, IconButton, Popover} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationEquipment,
  ApplicationView,
  Dispositions,
  EncryptedField,
  formatDateWithoutTimezone,
  isLocked,
  MerchantView,
  PCIFee,
  PCIFeeValueToString,
  SensitiveFieldCaptions,
  useMerchants,
  UserRoles,
  useSensitiveData,
} from '@ozark/common';
import {
  Line,
  SendEmailDialog,
  SummaryBox,
  SummaryBoxPricing,
  ViewingSensitiveData,
} from '@ozark/common/components';
import {BoxEllipsis} from '@ozark/common/components/common/BoxEllipsis';
import {
  BusinessInfoWebSiteItemName,
  getWebsiteItemForBusinessInfoSummaryColumns,
} from '@ozark/common/helpers';
import {format as formatTimeZoned} from 'date-fns-tz';
import React, {useCallback, useMemo, useState} from 'react';
import {generatePath, Link} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {MERCHANT_DETAILS} from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {EditAgentDialog, EditAgentDialogDataModel} from './EditAgentDialog';
import {Activity} from './Elements/Activity';
import {Bar} from './Elements/Bar';
import {Boarding} from './Elements/Boarding';
import {Location} from './Elements/Location';

const useStyles = makeStyles(theme => ({
  secretTextField: {
    margin: theme.spacing(2, 0, 3),
  },
  feeColumn: {
    paddingLeft: theme.spacing(2),
  },
  maskedValue: {
    color: theme.palette.primary.main,
    display: 'flex',
  },
  visibilityIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatCurrency = (value: number | null | '' | string) => {
  if (!value) return '$0';

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value as number);
};

export const Overview = ({application}: {application: ApplicationView}) => {
  const classes = useStyles();
  const {authProfile, isAllowedToEditApplication} = useStore();
  const [loading, setLoading] = useState(false);
  const [decryptedText, setDecryptedText] = useState<string | null>(null);
  const [modalLabel, setModalLabel] = useState<string | null>(null);
  const [editAgentDialogOpen, setEditAgentDialogOpen] = useState(false);
  const [sendEmailDialogOpen, setSendEmailDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedEquipment, setSelectedEquipment] = useState<ApplicationEquipment | null>(null);
  const {documents: merchants} = useMerchants();
  const {decryptField} = useSensitiveData(application.id);
  const merchantData = useMemo<MerchantView | null>(() => {
    if (!merchants.data || !application?.merchantUid) {
      return null;
    }

    const data = merchants.data?.find(merchant => merchant.id === application.merchantUid) ?? null;

    if (!data) {
      console.error(
        `Merchant with uid ${application.merchantUid} is not found in merchant users list`
      );
    }

    return data;
  }, [application.merchantUid, merchants.data]);

  const handleEquipmentDetailClick =
    (equipment: ApplicationEquipment) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedEquipment(equipment);
      setAnchorEl(event.currentTarget);
    };

  const handleCloseMenu = () => {
    setSelectedEquipment(null);
    setAnchorEl(null);
  };

  const showGroupSquare = [application?.agent, application.group.logoUrl]
    .map(condition => Boolean(condition))
    .includes(true);

  const locked = authProfile.data
    ? isLocked(authProfile.data?.role, application.disposition)
    : true;

  const handleDecryptClick = useCallback(
    async (
      encryptedField: EncryptedField,
      humanFieldName: string,
      modalLabel: string,
      returnEncryptedValue: boolean
    ) => {
      const plainText = await decryptField(encryptedField, humanFieldName, returnEncryptedValue);
      if (!plainText) {
        return;
      }
      setModalLabel(modalLabel);
      setDecryptedText(plainText);
    },
    [decryptField]
  );

  const handleDecryptSSNClick = async () => {
    await handleDecryptClick(
      EncryptedField.socialSecurityNumber,
      'SSN',
      SensitiveFieldCaptions.socialSecurityNumber,
      false
    );
  };

  const handleTaxIdClick = useCallback(() => {
    setModalLabel(SensitiveFieldCaptions.viewTaxId);
    setDecryptedText(application.federalTaxId);
  }, [application.federalTaxId]);

  const handleBankAccountNumberClick = useCallback(() => {
    setModalLabel(SensitiveFieldCaptions.bankAccountNumber);
    setDecryptedText(application.bankAccountNumber);
  }, [application.bankAccountNumber]);

  const handleRoutingNumberClick = useCallback(() => {
    setModalLabel(SensitiveFieldCaptions.routingNumber);
    setDecryptedText(application.routingNumber);
  }, [application.routingNumber]);

  const closeModal = () => {
    setDecryptedText(null);
    setModalLabel(null);
  };

  const readonly = !isAllowedToEditApplication(application);

  const editAgentDialogModel: EditAgentDialogDataModel = {
    applicationId: application.id,
    groupId: application.group.id,
    agentId: application.agent?.id,
    merchantUid: application.merchantUid,
    merchantLastFirstName: merchantData
      ? `${merchantData.firstName ?? ''} ${merchantData.lastName ?? ''}`
      : undefined,
  };

  const getIdentificationColumns = () => {
    let columns = {
      'Application ID': {
        value: application.distinguishableId,
        width: '50%',
      },
    } as Line;
    columns['Merchant ID'] = {
      value: <BoxEllipsis>{application.mid || 'Not Boarded'}</BoxEllipsis>,
      width: '50%',
    };

    if (application.referenceApp) {
      columns['Reference Application ID'] = (
        <Link to={`${ROUTES.APPLICATIONS}/${application.referenceApp!.appId}`}>
          {application.referenceApp.distinguishableId}
        </Link>
      );
    }

    columns['Internal ID'] = application.id;

    if (application.leadId) {
      columns['Sales Lead'] = (
        <Link to={generatePath(ROUTES.SALES_LEADS_DETAILS, {leadId: application.leadId})}>
          Lead Details
        </Link>
      );
    }

    return [columns] as [Line];
  };

  const getClonedAppInfoColumns = () => {
    if (!application.referenceApp) {
      throw new Error('Reference application is not set');
    }

    let columns = {
      'Application Started Date': formatTimeZoned(
        application.referenceApp.createdAt.toDate(),
        'MM/dd/yyyy h:mm a'
      ),
    } as Line;

    if (application.completedAt) {
      columns['Signed Date'] = formatTimeZoned(
        application.completedAt.toDate(),
        'MM/dd/yyyy h:mm a'
      );
    }

    if (application.referenceApp) {
      columns['Cloned Date'] = formatTimeZoned(application.createdAt.toDate(), 'MM/dd/yyyy h:mm a');
    }

    return [columns] as [Line];
  };

  const getRiskInfoColumns = () => {
    const applicationLink = generatePath(ROUTES.RISK_OVERVIEW_APPLICATION_DETAILS, {
      id: application.id,
      tab: 'riskDetails',
    });

    const getWrappedWithRiskDetailsValue = (value: string) => (
      <Link to={applicationLink}>{value}</Link>
    );

    const columns = {
      'Account Status': {
        value: getWrappedWithRiskDetailsValue(application.accountStatus?.status ?? 'N/A'),
      },
    } as Line;

    if (application.accountStatus?.subCategory) {
      columns['Account Sub Category'] = getWrappedWithRiskDetailsValue(
        application.accountStatus?.subCategory
      );
    }

    columns['Risk Status'] = (
      <BoxEllipsis title={application.riskStatus ?? 'N/A'} useLinesEllipsis>
        {getWrappedWithRiskDetailsValue(application.riskStatus ?? 'N/A')}
      </BoxEllipsis>
    );

    return [columns] as [Line];
  };

  const getBusinessInfoSummaryColumns = useCallback(() => {
    const items: Line = {
      'Legal Business Name': application.legalBusinessName,
      'Legal Business Address': application.businessAddress1 ? (
        <>
          {application.businessAddress1}
          {application.businessAddress2 || ''}
          <br />
          {application.businessCity}, {application.businessState} {application.businessZip}
        </>
      ) : (
        '-'
      ),
      DBA: application.doingBusinessAs,
      'DBA Address': application.businessAddress1 ? (
        <>
          {application.businessAddress1}
          {application.businessAddress2 || ''}
          <br />
          {application.businessCity}, {application.businessState} {application.businessZip}
        </>
      ) : (
        '-'
      ),
      'Business Phone': (
        <a href={`tel:${application.businessPhone}`}>
          {application.businessPhone || '(No Phone Provided)'}
        </a>
      ),
      'Ownership Type': application.ownershipType,
      'Tax ID': {
        value: !application.federalTaxId ? (
          '-'
        ) : (
          <span className={classes.maskedValue}>
            *****{application.federalTaxId.substring(6, 10)}
            <VisibilityIcon color="primary" className={classes.visibilityIcon} />
          </span>
        ),
        onClick: handleTaxIdClick,
      },
      'Bank Account #': {
        value: !application.bankAccountNumber ? (
          '-'
        ) : (
          <span className={classes.maskedValue}>
            *****
            {application.bankAccountNumber.substring(
              application.bankAccountNumber.length - 4,
              application.bankAccountNumber.length
            )}{' '}
            <VisibilityIcon color="primary" className={classes.visibilityIcon} />
          </span>
        ),
        onClick: handleBankAccountNumberClick,
      },
      'Bank Routing #': {
        value: !application.routingNumber ? (
          '-'
        ) : (
          <span className={classes.maskedValue}>
            *****
            {application.routingNumber.substring(
              application.routingNumber.length - 4,
              application.routingNumber.length
            )}{' '}
            <VisibilityIcon color="primary" className={classes.visibilityIcon} />
          </span>
        ),
        onClick: handleRoutingNumberClick,
      },
      'EBT #FNS': application.ebtFNS,
    };
    const element = getWebsiteItemForBusinessInfoSummaryColumns(application);
    if (element) {
      items[BusinessInfoWebSiteItemName] = {
        value: element,
      };
    }
    const result: [Line] | [Line, Line] = [items];
    return result;
  }, [
    application,
    classes.maskedValue,
    classes.visibilityIcon,
    handleBankAccountNumberClick,
    handleRoutingNumberClick,
    handleTaxIdClick,
  ]);

  return (
    <>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={12}>
          <Bar
            application={application}
            locked={locked}
            loading={loading}
            setLoading={setLoading}
          />
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <RiskSection application={application} />*/}
        {/*</Grid>*/}
        <Grid item xs={9}>
          <Grid container spacing={2} direction="row" alignItems="stretch">
            <Grid item xs={4}>
              <SummaryBox
                groups={[
                  ...(application.referenceApp
                    ? [
                        {
                          icon: <AccountCircleIcon />,
                          title: 'Cloned App Info',
                          columns: getClonedAppInfoColumns(),
                        },
                      ]
                    : []),
                  showGroupSquare
                    ? {
                        icon: <GroupIcon />,
                        title: 'Group & Agent Info',
                        columns: [
                          {
                            Group: {
                              value: application.group ? (
                                <>
                                  <CardMedia
                                    sx={{
                                      objectFit: 'contain',
                                      maxWidth: '50%',
                                      marginBottom: '5px',
                                    }}
                                    component="img"
                                    image={application.group.logoUrl}
                                  ></CardMedia>
                                  {application.group.name}
                                </>
                              ) : (
                                'No Assigned Group'
                              ),
                              onClick: !readonly ? () => setEditAgentDialogOpen(true) : undefined,
                            },
                            Agent: {
                              value: application.agent
                                ? `${application.agent.firstName} ${application.agent.lastName}`
                                : 'No Assigned Agent',
                              onClick: !readonly ? () => setEditAgentDialogOpen(true) : undefined,
                            },
                            Merchant: merchantData ? (
                              <Link
                                to={generatePath(MERCHANT_DETAILS, {id: application.merchantUid})}
                              >
                                {merchantData.firstName ?? ''} {merchantData.lastName ?? ''}
                              </Link>
                            ) : merchants.promised ? (
                              '-'
                            ) : (
                              'Not Registered'
                            ),
                          },
                        ],
                      }
                    : null,
                  {
                    icon: <AccountCircleIcon />,
                    title: 'Identification',
                    columns: getIdentificationColumns(),
                  },
                  ...(application.disposition === Dispositions.boarded
                    ? [
                        {
                          icon: <WarningIcon />,
                          title: 'Risk Info',
                          columns: getRiskInfoColumns(),
                        },
                      ]
                    : []),
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryBox
                groups={[
                  {
                    icon: <BusinessIcon />,
                    title: 'Business Info',
                    columns: getBusinessInfoSummaryColumns(),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryBox
                groups={[
                  {
                    icon: <ContactPhoneIcon />,
                    title: 'Signer Info',
                    columns: [
                      {
                        'Owner Name': `${application.firstName || ''} ${
                          application.lastName || ''
                        }`,
                        'Email Address': {
                          value: (
                            <>
                              {!!application.registrationEmailSentAt && (
                                <Box sx={{fontSize: '12px', fontStyle: 'italic'}}>
                                  Registration email was sent{' '}
                                  {formatTimeZoned(
                                    application.registrationEmailSentAt.toDate(),
                                    'MM/dd/yyyy h:mm a'
                                  )}
                                </Box>
                              )}
                              <a href={`mailto:${application.customerServiceEmail}`}>
                                {application.customerServiceEmail}
                              </a>
                            </>
                          ),
                          secondaryAction:
                            application.disposition === Dispositions.boarded ? (
                              <IconButton
                                onClick={
                                  authProfile.data?.role === UserRoles.admin
                                    ? () => setSendEmailDialogOpen(true)
                                    : undefined
                                }
                                size="large"
                              >
                                <SendIcon />
                              </IconButton>
                            ) : null,
                        },
                        'Phone Number': (
                          <a href={`tel:${application.businessPhone}`}>
                            {application.businessPhone || '(No Phone Provided)'}
                          </a>
                        ),
                        'Cell Number': (
                          <a href={`tel:${application.cellPhone}`}>
                            {application.cellPhone || '(No Phone Provided)'}
                          </a>
                        ),
                        'Home Address': application.homeAddress1 ? (
                          <>
                            {application.homeAddress1}
                            {application.homeAddress2 || ''}
                            <br />
                            {application.homeCity}, {application.homeState} {application.homeZip}
                          </>
                        ) : (
                          '-'
                        ),
                        'Date of Birth': application.dateOfBirth
                          ? formatDateWithoutTimezone(application.dateOfBirth.toDate())
                          : '-',
                        'Social Security #': {
                          value: (
                            <span className={classes.maskedValue}>
                              *****{application.socialSecurityNumberLast4}
                              <VisibilityIcon color="primary" className={classes.visibilityIcon} />
                            </span>
                          ),
                          onClick: handleDecryptSSNClick,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryBox
                groups={[
                  {
                    icon: <CreditCardIcon />,
                    title: 'Processing Info',
                    columns: [
                      {
                        'Processing Type': application.processingType,
                        'Business Type': application.businessType,
                        MCC: application.mcc ?? '-',
                        'V/MC/DISC Monthly Sales': application.estimatedMonthlyCreditCardSales
                          ? formatter.format(application.estimatedMonthlyCreditCardSales)
                          : '-',
                        'AMEX Monthly Sales': application.estimatedMonthlyCreditCardSalesAmex
                          ? formatter.format(application.estimatedMonthlyCreditCardSalesAmex)
                          : '-',
                        'Average Ticket': application.estimatedAverageSale
                          ? formatter.format(application.estimatedAverageSale)
                          : '-',
                        'High Ticket': application.estimatedHighestSale
                          ? formatter.format(application.estimatedHighestSale)
                          : '-',
                      },
                    ],
                  },
                ]}
              />
            </Grid>

            <Grid item xs={4}>
              <SummaryBox
                groups={[
                  {
                    icon: <BuildIcon />,
                    title: 'Equipment',
                    columns: [
                      [application.equipment, ...(application.equipmentAdditional || [])].reduce(
                        (previous: any, e, i) => {
                          const name = e?.isVarSheet ? e.softwareName : e?.name;
                          if (!e?.name || !name) return previous;
                          previous[`Equipment Type #${i + 1}`] = {
                            value: name,
                            secondaryAction: (
                              <>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={handleEquipmentDetailClick(e)}
                                >
                                  See Details
                                </Button>
                                {e?.VARSheetAttachmentUrl && (
                                  <Button variant="outlined" size="small" sx={{marginLeft: 1}}>
                                    <a
                                      href={e?.VARSheetAttachmentUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Download VAR Sheet
                                    </a>
                                  </Button>
                                )}
                              </>
                            ),
                          };
                          return previous;
                        },
                        {}
                      ),
                    ],
                  },
                ]}
              />
            </Grid>

            <Grid item xs={4}>
              <SummaryBox
                groups={[
                  {
                    icon: <ReceiptIcon />,
                    title: 'Monthly Fees',
                    columns: [
                      {
                        'Account on File': formatCurrency(application.rateSet?.accountOnFileFee),
                        'Retrieval Fee': formatCurrency(application.rateSet?.retrievalFee),
                        'AVS Fee': formatCurrency(application.rateSet?.avsFee),
                        'Debit Access Fee': formatCurrency(application.rateSet?.debitAccessFee),
                        'PCI Fee': application.rateSet?.pciFee
                          ? PCIFeeValueToString(
                              application.rateSet?.pciFee as PCIFee,
                              application.rateSet?.pciFeeValue
                            )
                          : '-',
                        'Annual Fee': formatCurrency(application.rateSet?.annualFee),
                      },
                      {
                        'Chargeback Fee': formatCurrency(application.rateSet?.chargebackFee),
                        'Monthly Minimum Fee': formatCurrency(
                          application.rateSet?.monthlyMinimumFee
                        ),
                        'Batch Fee': formatCurrency(application.rateSet?.batchFee),
                        "Add'l Service Fee": formatCurrency(
                          application.rateSet?.additionalServicesFee
                        ),
                        'Early Deconversion Fee': formatCurrency(
                          application.rateSet?.earlyDeconversionFee
                        ),
                        'Regulatory Fee': formatCurrency(application.rateSet?.regulatoryFee),
                      },
                    ],
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.feeColumn}>
          <SummaryBoxPricing application={application} />
        </Grid>

        <Grid item xs={3}>
          <Location application={application} />
        </Grid>
        <Grid item xs={6}>
          <Activity application={application} />
        </Grid>
        <Grid item xs={3}>
          <Boarding
            application={application}
            locked={locked || readonly}
            loading={loading}
            setLoading={setLoading}
          />
        </Grid>
      </Grid>
      {decryptedText && modalLabel && (
        <ViewingSensitiveData
          decryptedText={decryptedText}
          showSecurityIcon
          label={modalLabel}
          onClose={closeModal}
        />
      )}
      {editAgentDialogOpen && (
        <EditAgentDialog
          dialogData={editAgentDialogModel}
          onClose={() => setEditAgentDialogOpen(false)}
        />
      )}
      {sendEmailDialogOpen && application.mid && (
        <SendEmailDialog
          applicationId={application.id}
          initialEmail={application.customerServiceEmail!}
          onClose={() => setSendEmailDialogOpen(false)}
        />
      )}
      {anchorEl && selectedEquipment && (
        <Popover
          open={true}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <SummaryBox
            groups={[
              {
                icon: <BuildIcon />,
                title: 'Equipment Details',
                columns: [
                  {
                    'Equipment Type': selectedEquipment.name,
                    'Placement Type': selectedEquipment.placementType,
                    'TID #': selectedEquipment.tid || '-',
                    'Shipping Address': application.shipping?.address1 ? (
                      <>
                        {application.homeAddress1}
                        {application.homeAddress2 || ''}
                        <br />
                        {application.homeCity}, {application.homeState} {application.homeZip}
                      </>
                    ) : (
                      '-'
                    ),
                    'Equipment Tracking #': selectedEquipment.sendTrackingNumber ? (
                      <a
                        href={`http://fedex.com/fedextrack/?tracknumbers=${selectedEquipment.sendTrackingNumber}&cntry_code=us`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {selectedEquipment.sendTrackingNumber}
                      </a>
                    ) : (
                      '-'
                    ),
                    'Return Tracking #': selectedEquipment.receiveTrackingNumber ? (
                      <a
                        href={`http://fedex.com/fedextrack/?tracknumbers=${selectedEquipment.receiveTrackingNumber}&cntry_code=us`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {selectedEquipment.receiveTrackingNumber}
                      </a>
                    ) : (
                      '-'
                    ),
                  },
                ],
              },
            ]}
          />
        </Popover>
      )}
    </>
  );
};
